import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { CommonService } from "./common/common.service";


@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly _commService: CommonService, 
    private myRoute: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this._commService.isLoggedIn()) {
      //const currentRoute = state.url;
     // this.checkPermission(currentRoute);
     return true;
    } else {
      this.myRoute.navigate(["/auth/login"]);
    }
  }

  checkPermission(currentRoute: string) {
    if(currentRoute == '/pages/access-mgt'){
      if(JSON.parse(localStorage.getItem('user')).role == 1){
        return true;
      } else{
        this.myRoute.navigate(["pages/dashboard"]);
        return false;
      }
    } else if(currentRoute == '/pages/payroll-mgt'){
      if(JSON.parse(localStorage.getItem('user')).role == 1 || (
        JSON.parse(localStorage.getItem('user')).permission[6].isAdd && JSON.parse(localStorage.getItem('user')).permission[6].isEdit && 
        JSON.parse(localStorage.getItem('user')).permission[6].isView && JSON.parse(localStorage.getItem('user')).permission[6].isDelete
      )){
        return true;
      } else{
        this.myRoute.navigate(["pages/dashboard"]);
        return false;
      }
    } else {
      return true;
    }
   // const permissionByRole = ROLE_PERMISSION.find(role => role.name === localStorage.role_name);
  //  return permissionByRole[currentRoute];
  }
}
