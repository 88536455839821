import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "../common/common.service";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
  providedIn: "root",
})
export class GetInterceptorService {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private commonService: CommonService,
    private ngxService: NgxUiLoaderService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.ngxService.start();
    let whiteLabel;
    let theme;
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if(event.status===204){
              localStorage.clear();
              this.router.navigateByUrl("/auth/login");
            } else {
              this.commonService.showLoader = false;
              this.ngxService.stop();
            }
          }
        },
        (error: any) => {
          this.commonService.showLoader = false;
          if (error["status"] === 400) {
            this.toastr.error(error["error"]["message"]);
          } else if (error["status"] === 401) {
            this.router.navigateByUrl("/auth/login");
          }
          this.ngxService.stop();
        }
      )
    );
  }
}
