import { Injectable, EventEmitter, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
//import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  _showLoader: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  socket;

  profileData = new BehaviorSubject<any>("");
  isHideShow = new BehaviorSubject<any>("");
  isDarkTheme = new BehaviorSubject<any>("");

  constructor(
    //  private readonly toastr: ToastrManager,
    private toastrService: ToastrService
  ) {
    if (localStorage.getItem("betnahrin_admin")) {
      this.setProfile();
    }
  }

  isLoggedIn() {
    if (localStorage.getItem("betnahrin_admin")) return true;
  }

  set showLoader(val: boolean) {
    this._showLoader.emit(val);
  }

  setProfile() {
    var value = JSON.parse(localStorage.getItem("betnahrin_admin"));
    this.profileData.next(value);
  }

  getProfile(): Observable<boolean> {
    return this.profileData.asObservable();
  }

  successMsg(message) {
    this.toastrService.success(message, "", {
      closeButton: false,
      progressBar: true,
      progressAnimation: "decreasing",
    });
  }

  errorMsg(message) {
    this.toastrService.error(message, "", {
      closeButton: false,
      progressBar: true,
      progressAnimation: "decreasing",
    });
  }

  noSpace(event) {
    if (event.which === 32 && !event.target.value.length)
      event.preventDefault();
  }

  onlyNumbersWithoutSpace(event) {
    var k;
    console.log(event);

    k = event.charCode;
    return k >= 48 && k <= 57;
  }

  validateImageFile(fileData) {
    if (
      fileData.type == "image/jpeg" ||
      fileData.type == "image/png" ||
      fileData.type == "image/jpg" ||
      fileData.type == "image/svg" ||
      fileData.type == "image/webp" ||
      fileData.type == "image/gif"
    ) {
      return true;
    } else {
      this.errorMsg("Only Image file is accepted!");
      return false;
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    console.log(isValid);

    return isValid ? null : { whitespace: true };
  }

  public findInvalidControls(form: FormGroup) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid);
  }
}
