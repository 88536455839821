import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material/material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SetInterceptorService } from './services/set-interceptor/set-interceptor.service';
import {GetInterceptorService} from './services/get-interceptor/get-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule} from '@angular/router';
//import { ToastrModule } from 'ng6-toastr-notifications';
import { NgxMaskModule } from 'ngx-mask'
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SlickCarouselModule,
    MaterialModule,
    AppRoutingModule,
    CKEditorModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    ToastrModule.forRoot(),
    GooglePlaceModule,
    NgxEchartsModule,
    NgxUiLoaderModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxMaskModule.forRoot({
      showMaskTyped : true,
      // clearIfNotMatch : true
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAT2e_4pNXD4FS3jS1IZ_M_Y6mAOO-B2oU',
      libraries: ['places']
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SetInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GetInterceptorService, multi: true},
    NgxUiLoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
