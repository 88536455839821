import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [

  {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  {
    canActivate: [AuthGuard],
    path: 'superadmin', 
    loadChildren: () => import('./superadmin/superadmin.module').then(m => m.SuperadminModule) 
  },
  {path:'', pathMatch: 'full', redirectTo: 'auth'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
